import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

// Connects to data-controller="tomselect"
export default class extends Controller {
  static values = {
    options: Object
  }
  
  connect() {
    this.ts = new TomSelect(this.element, this.optionsValue)
  }
  
  clear() {
    this.ts.clear()
  }
}
