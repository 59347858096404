import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="joblist"
export default class extends Controller {
  static targets = [ "list", "details"]
  
  toggle() {
    if (this.listTarget.classList.contains("hidden")) {
      this.detailsTarget.classList.add("hidden")
      this.listTarget.classList.remove("hidden")
    } else {
      this.listTarget.classList.add("hidden")
      this.detailsTarget.classList.remove("hidden")
    }
  }
}
