import { Controller } from "@hotwired/stimulus"
import ahoy from "ahoy.js"

ahoy.configure({ cookies: false })

// Connects to data-controller="ahoy"
export default class extends Controller {
  connect() {
    ahoy.configure({ cookies: false })
  }
  
  track({ params: { name, properties } }) {
    ahoy.track(name, properties)
  }

}

