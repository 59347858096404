import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'
import TomSelect from "tom-select"

// Connects to data-controller="country-select"
export default class extends Controller {
  static targets = ["select", "citySelect"]

  connect() {
    const options = {
      create: false,
    }
    new TomSelect(this.selectTarget, options) 
  }

  async change(event) {
    const country = event.target.selectedOptions[0].value
    const target = this.citySelectTarget.id

    await get(`/cities?target=${target}&country=${country}`, {
      responseKind: "turbo-stream"
    })


    const observer = new MutationObserver(() => {
      this.dispatch("changed", {detail: {countryId: country}})
      observer.disconnect()
    })
    observer.observe(this.citySelectTarget, {childList: true})
    
    this.citySelectTarget.disabled = false
    this.citySelectTarget.placeholder = "Search cities..."

  
  }
}
