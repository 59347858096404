import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="obfuscate"
export default class extends Controller {
  static values = { address: String }

  connect() {
    const decoded = this.decode(this.addressValue);
    this.element.value = decoded;
  }

  decode(s) {
    const codes = Array.from(s, (c) => c.charCodeAt(0));
    const seed = codes.shift() - 64;
    const random16 = this.xorshift4(seed);
    return String.fromCharCode(...codes.map(c => { return( ((c - 0x20) - random16() + 94) % 94 + 0x20 ) }));
  }

  xorshift4(seed) {
    var state = seed;
    return function() {
      const bit = ((state & 0x08) >> 3) ^ ((state & 0x04) >> 2);
      state = (state << 1) & 0x0f | bit;
      return state;
    }
  }
}
