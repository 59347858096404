import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="apply-method"
export default class extends Controller {

  static targets = ["input", "applyEmail", "applyRedirect"]

  selected(event) {
    const id = event.target.id

    this.inputTargets.forEach(target => {
      target.value = ""
    });

    if (id == "job_apply_method_email") {
      this.applyRedirectTarget.classList.add("hidden")
      this.applyEmailTarget.classList.remove("hidden")

    } else if (id == "job_apply_method_redirect") {
      this.applyEmailTarget.classList.add("hidden")
      this.applyRedirectTarget.classList.remove("hidden")
    }
  }
}
