import { Controller } from "@hotwired/stimulus"
import ThreeGlobe from "three-globe";
import { WebGLRenderer, Scene, Vector3, Quaternion } from "three";
import {
  PerspectiveCamera,
  AmbientLight,
  DirectionalLight,
  Color,
  Fog,
  // AxesHelper,
  // DirectionalLightHelper,
  // CameraHelper,
  PointLight,
  SphereGeometry,
} from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import spaceGroteskFontData from "../fonts/space-grotesk.json"
import globeDayTexture from "../images/globe-day.jpg"

let mouseX = 0;
let mouseY = 0;
let windowHalfX = window.innerWidth / 2;
let windowHalfY = window.innerHeight / 2;

// Connects to data-controller="threejs"
export default class extends Controller {
  static values = { color: String }
  static targets = ["canvas", "spinner"]

  rotate({ params: { latitude, longitude } }) {
    const duration = 2000;
    const targetRadius = 110;
    const lat = latitude * (Math.PI / 180);
    const lon = longitude * (Math.PI / 180);

    // New parameters for arc control
    const maxArcHeight = targetRadius * 0.4; // 50% higher than the target radius
    const arcSmoothness = 0.8; // Controls the smoothness of the arc

    const targetPosition = new Vector3(
      Math.cos(lat) * Math.sin(lon),
      Math.sin(lat),
      Math.cos(lat) * Math.cos(lon)
    ).normalize();

    const currentPosition = this.controls.object.position.clone().normalize();
    const currentRadius = this.controls.object.position.length();

    const currentQuaternion = new Quaternion().setFromUnitVectors(new Vector3(0, 1, 0), currentPosition);
    const targetQuaternion = new Quaternion().setFromUnitVectors(new Vector3(0, 1, 0), targetPosition);

    const startTime = performance.now();

    const animateRotation = (currentTime) => {
      const elapsedTime = currentTime - startTime;
      const t = Math.min(elapsedTime / duration, 1);

      const interpolatedQuaternion = currentQuaternion.clone().slerp(targetQuaternion, t);
      const interpolatedPosition = new Vector3(0, 1, 0).applyQuaternion(interpolatedQuaternion);

      // Smooth arc calculation
      const arcProgress = 1 - Math.pow(1 - t, arcSmoothness); // Easing function for smoother arc
      const arcHeight = Math.sin(arcProgress * Math.PI) * maxArcHeight;

      // Calculate smooth radius with enhanced arc
      const smoothRadius = currentRadius + (targetRadius - currentRadius) * t + arcHeight;

      // Ensure the camera doesn't go below the starting radius

      this.controls.object.position.copy(interpolatedPosition.multiplyScalar(smoothRadius));
      this.controls.update();

      if (t < 1) {
        requestAnimationFrame(animateRotation);
      }
    };

    requestAnimationFrame(animateRotation);
    this.controls.zoomSpeed = 0.981;
  }


  async connect() {

    this.renderer = new WebGLRenderer({ antialias: true, alpha: true });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    // renderer.outputEncoding = THREE.sRGBEncoding;

    this.canvasTarget.appendChild(this.renderer.domElement);
    // Initialize scene, light
    this.scene = new Scene();

    this.scene.add(new AmbientLight(0xffffff, 0.5));

    // Initialize camera, light
    this.camera = new PerspectiveCamera();
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();

    var dLight = new DirectionalLight(0xffffff, 2);
    dLight.position.set(-800, 2000, 400);
    this.camera.add(dLight);

    var dLight1 = new DirectionalLight(0xffffff, 2);
    dLight1.position.set(-200, 500, 200);
    this.camera.add(dLight1);

    var dLight3 = new DirectionalLight(0xffffff, 2);
    dLight3.position.set(800, -2000, 400);
    this.camera.add(dLight3);

    //var dLight2 = new PointLight(0xffffff, 100);
    //dLight2.position.set(-200, 500, 200);
    //this.camera.add(dLight2);

    this.camera.position.z = 400;
    this.camera.position.x = 0;
    this.camera.position.y = 0;
    this.camera.setViewOffset(window.innerWidth, window.innerHeight, -255, 0, window.innerWidth, window.innerHeight)



    this.scene.add(this.camera);

    // Additional effects
    this.scene.fog = new Fog(0x535ef3, 400, 2000);

    // Helpers
    // const axesHelper = new AxesHelper(800);
    // scene.add(axesHelper);
    // var helper = new DirectionalLightHelper(dLight);
    // scene.add(helper);
    // var helperCamera = new CameraHelper(dLight.shadow.camera);
    // scene.add(helperCameara);

    // Initialize controls
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.enableDamping = true;
    this.controls.dynamicDampingFactor = 0.01;
    this.controls.enablePan = false;
    this.controls.minDistance = 101;
    this.controls.maxDistance = 300;

    const zoom = this.controls.target.distanceTo(this.controls.object.position)
    const rotateSpeed = (zoom - 101) / 400;
    const zoomSpeed = (zoom - 101) / 50

    this.controls.rotateSpeed = rotateSpeed;
    this.controls.zoomSpeed = zoomSpeed;
    this.controls.autoRotate = false;

    function getZoom() {
      const zoom = this.controls.target.distanceTo(this.controls.object.position)

      const rotateSpeed = (zoom - 101) / 400;
      const zoomSpeed = (zoom - 101) / 50


      this.controls.rotateSpeed = rotateSpeed
      this.controls.zoomSpeed = zoomSpeed
    }
    document.addEventListener("wheel", getZoom.bind(this))

    //this.controls.minPolarAngle = Math.PI / 3.5;
    //this.controls.maxPolarAngle = Math.PI - Math.PI / 3;

    await this.initGlobe();

    this.animate();



    window.addEventListener("resize", this.debounce(() => {
      this.camera.aspect = window.innerWidth / window.innerHeight;
      this.camera.updateProjectionMatrix();
      windowHalfX = window.innerWidth / 1.5;
      windowHalfY = window.innerHeight / 1.5;
      this.renderer.setSize(window.innerWidth, window.innerHeight);
    }), false);





  }

  debounce(func, timeout = 300) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
  }

  disconnect() {
    this.canvasTarget.replaceChildren();

    this.spinnerTarget.classList.remove("hidden")
  }



  // SECTION Globe
  async initGlobe() {
    const response = await fetch('/api/cities');
    const data = await response.json();

    const gData = data.cities.map((city) => ({
      lat: city.latitude,
      lng: city.longitude,
      name: city.display_name,
      size: 0.1,
      color: '#344055'
    }));

    // Initialize the Globe
    this.Globe = new ThreeGlobe({
      waitForGlobeReady: true,
      animateIn: true,
    })
      .globeImageUrl(globeDayTexture)
      //.polygonsData(countries.features)

      .showAtmosphere(true)
      .atmosphereColor("#66DCFF")
      .atmosphereAltitude(0.25)
      //.polygonCapColor((e) => {
      //  return "rgba(0, 200, 0, 0)";
      //})
      //  .polygonSideColor(() => 'rgba(0, 200, 0, 0)')
      //  .polygonStrokeColor(() => '#dadada')
      //  .polygonAltitude(0.001)
      .labelsData(gData)
      .labelText(d => d.name)
      .labelSize('size')
      .labelTypeFace(spaceGroteskFontData)
      .labelDotRadius(d => d.size / 2)
      .labelAltitude(0.0001)
      .labelColor('color');

    //this.Globe.rotateX(0)
    //this.Globe.rotateY(0)
    //this.Globe.rotateY(-Math.PI * (5 / 9));
    //this.Globe.rotateZ(-Math.PI / 6);
    const globeMaterial = this.Globe.globeMaterial();
    globeMaterial.color = new Color("#007FFF");
    globeMaterial.shininess = 0.7;

    this.Globe.onGlobeReady(() => {
      this.spinnerTarget.classList.add("hidden")
    })

    // NOTE Cool stuff
    // globeMaterial.wireframe = true;

    this.scene.add(this.Globe);
  }

  onMouseMove(event) {
    mouseX = event.clientX - windowHalfX;
    mouseY = event.clientY - windowHalfY;
  }


  animate() {
    this.camera.lookAt(this.scene.position);
    this.controls.update();
    this.renderer.render(this.scene, this.camera);
    requestAnimationFrame(this.animate.bind(this));

  }


}
