import { Application } from "@hotwired/stimulus"
import Notification from '@stimulus-components/notification'
import Dialog from '@stimulus-components/dialog'
import Clipboard from '@stimulus-components/clipboard'

const application = Application.start()

application.register('notification', Notification)
application.register('dialog', Dialog)
application.register('clipboard', Clipboard)


// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

export { application }
