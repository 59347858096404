import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"
import { post } from '@rails/request.js'

// Connects to data-controller="ts-select"
export default class extends Controller {

  async create(input, callback) {
    const data = {
      city: {name: input, country_id: this.countryId}
    }
    const response = await post("/api/cities", {body: JSON.stringify(data)})
    const city = await response.json

    return await callback({ value: city.id, text: city.name })
  }

  connect() {
    const options = {
      create: false, //this.create.bind(this),
	    /* createFilter: function(input) {
		    input = input.toLowerCase();
        const options = Object.values(this.options).map((value) => value.text.toLowerCase())
		    return !options.includes(input);
	    } */
    }

    this.ts = new TomSelect(this.element, options)
  }

  sync({ detail: { countryId }}) {
    this.countryId = countryId

    this.ts.settings.placeholder = "Search"
    this.ts.enable()

    this.ts.clear()
    this.ts.clearOptions()
    this.ts.sync()
    this.ts.setValue([])
  }
}
