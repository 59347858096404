import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="form-tabs"
export default class extends Controller {
  static targets = ["input", "tab"]

  select(event) {
    this.inputTargets.forEach(input => {
      this.dispatch("select", { target: input})
    })
    
    this.tabTargets.forEach(tab => {
      if (tab.id == event.target.id) {
        tab.classList.remove("hidden") 
      } else {
        tab.classList.add("hidden")
      }      

    })

  }  


}
